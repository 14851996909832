/* CustomInput.css */
.custom-input-container {
    position: relative;
    margin-bottom: 0.5rem;
}

.custom-input-label {
    position: absolute;
    top: 0;
    left: -15px;
    transform: translateY(50%);
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    color: #3f7fff7c;
}

.custom-input-field {
    border-radius: 0%;
    height: 60px;
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #D1985F;
    background-color: transparent;
    color: #D1985F;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.custom-input-field:focus {
    border-color: rgba(235, 12, 12, 0)important;
}

.custom-input-field:focus + .custom-input-label,
.custom-input-field:not(:placeholder-shown) + .custom-input-label {
    transform: translateY(-22%) translateX(20%);
    font-size: 0.75rem;
    color: #D1985F;
}

.custom-input-field::placeholder{
    color: #D1985F;
}

.custom-input-error {
    color: #dc35469b;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.custom-input .form-control {
    border: none;
    border-bottom: 1px solid #D1985F;
    border-radius: 0;
}

 .form-control:focus {
    color: #2F96C5;
    background-color: #42424300; 
    border-color: #D1985F;
    box-shadow: none;
    
}

.form-control:disabled{
    background-color: #868686;
    opacity: 1;
}