.box {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .box-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .box-content {
    font-size: 16px;
    line-height: 1.5;
  }

  .month {
    font-weight: 600;
    font-size: 23px;
    margin: -157px -60px 77px -270px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
}

.offerPrice {
  font-weight: 600;
  font-size: 45px;
  margin: -143px -144px 75px 75px;
  padding: 10px 16px;
  color: #ffffff;
  text-align: center;
}


  .planName {
    font-weight: 600;
    font-size: 17px;
    margin: -70px -45px 77px -255px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
}

.getStartImage {
  margin: -94px -88px 83px 93px;
}


.getStartImageText{
  font-weight: 600;
    font-size: 17px;
  color: #D1985F;
  margin: -120px -106px 83px 93px;
}

.rupee {
  font-weight: 700;
  font-size: 25px;
  margin: -140px -390px 70px -271px;
  padding: 10px 16px;
  color: #ffffff;
  text-align: center;
}

.oldPrice {
    text-decoration: line-through;
    font-weight: 400;
    font-size: 25px;
    margin: -150px -335px 86px -272px;
    padding: 10px 16px;
    color: #ffffff;
    text-align: center;
}

.hover01 figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  .zoom-effect {
    transform: scale(1);
  }
  
  .image-container:hover .zoom-effect {
    transform: scale(1.1);
  }