
   

    .container-upcoming {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px; 
      }
      
      .page-heading {
        text-align: center;
        margin-bottom: 40px;
      }
      
      .container-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; /* Distribute items evenly */
        align-content: center;
      }
      
      .container-item {
        margin-bottom: 20px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f9f9f9;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: calc(33.33% - 20px); /* Adjusted width for three items in a row */
      }
      
      .container-image {    
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
      
      .container-title {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
      }
      
      .container-subtitle {
        margin-top: 10px;
        color: #666;
      }
      
      .container-description {
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
      }
       
      .image-container {
        position: relative;
      }
      
      .overlay-upcoming {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.39); /* Dark overlay */
        z-index: 1;
        border-radius: 8px;
      }
      
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(0, 0, 0); /* White color for the play button icon */
        z-index: 2;
        font-size: 3rem; /* Adjust the size as needed */
      }

      .custom-h3 {
        color: rgb(255, 255, 255); /* Custom color for h3 */
        font-weight: bold; /* Custom font weight for h3 */
      }
      
      .custom-h4 {
        color: #D1985F; /* Same color as h3 */
        font-weight: bold; /* Same font weight as h3 */
      }
      
      .custom-p {
        
        font-size: 12px; /* Adjust font size as needed */
        font-weight: lighter; /* Lighter font weight for paragraph */
      }

      .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
      }
      
      .loading-overlay .spinner-border {
        color: #fff; /* Color of the spinner */
      }

      .loader-upcoming {
        /* margin: 71px -51px 6px 149px; */
        /* --s: 25px; */
        width: 70px;
        --_d: calc(0.353*var(--s));
        /* width: calc(var(--s) + var(--_d)); */
        aspect-ratio: 1;
        display: grid;
        filter: drop-shadow(0 0 0 #fff);
        animation: l2 0.8s infinite;
      }
      .loader-upcoming:before {
        content: "";
        clip-path: polygon(var(--_d) 0,100% 0,100% calc(100% - var(--_d)),calc(100% - var(--_d)) 100%,0 100%,0 var(--_d));
        background:
          conic-gradient(from -90deg at var(--s) var(--_d),
           #fff 135deg,#666 0 270deg,#aaa 0);
      }
      @keyframes l2{
        50% {filter:drop-shadow(0 0 5px #fff)}
      }


body {
  background: black;
}

.buttonglow {
  background-color: #004A7F;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #F9AD3C;
    -webkit-box-shadow: 0 0 3px #F9AD3C;
  }

  50% {
    background-color: #F9AD3C;
    -webkit-box-shadow: 0 0 40px #F9AD3C;
  }

  100% {
    background-color: #F9AD3C;
    -webkit-box-shadow: 0 0 3px #F9AD3C;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #F9AD3C;
    -moz-box-shadow: 0 0 3px #F9AD3C;
  }

  50% {
    background-color: #F9AD3C;
    -moz-box-shadow: 0 0 40px #F9AD3C;
  }

  100% {
    background-color: #F9AD3C;
    -moz-box-shadow: 0 0 3px #F9AD3C;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #F9AD3C;
    box-shadow: 0 0 3px #F9AD3C;
  }

  50% {
    background-color: #F9AD3C;
    box-shadow: 0 0 40px #F9AD3C;
  }

  100% {
    background-color: #F9AD3C;
    box-shadow: 0 0 3px #F9AD3C;
  }
}

@keyframes glowing {
  0% {
    background-color: #F9AD3C;
    box-shadow: 0 0 3px #F9AD3C;
  }

  50% {
    background-color: #F9AD3C;
    box-shadow: 0 0 40px #F9AD3C;
  }

  100% {
    background-color: #F9AD3C;
    box-shadow: 0 0 3px #F9AD3C;
  }
}