.dropdown-item {
    color: white;
    --bs-dropdown-link-active-color: "green";
  }
  
  .dropdown-item:hover {
    background-color: #dddddd; /* Grey color on hover */
  }

  .btn-secondary {
    --bs-btn-color: #D1985F;
    --bs-btn-hover-color: #D1985F;
  }

  
  